const csrf = document.querySelector('meta[name="csrf-token"]')
  .attributes.content.value;

const defaultHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
  'x-csrf-token': csrf,
};
const defaultCredentials = 'same-origin';

const executeRequest = async (req, options = {}) => {
  try {
    const res = await fetch(req, {
      credentials: options.credentials || defaultCredentials,
      headers: options.headers || defaultHeaders,
    });

    if (res.status === 204) { // No Content
      return [];
    }

    let body;

    try {
      body = await res.clone().json();
    } catch (_) {
      try {
        body = await res.clone().text();
      } catch (e) {
        body = res.body;
      }
    }

    if (!res.ok) {
      throw body;
    }

    return body;
  } catch (err) {
    throw err;
  }
};

const request = async (url, method, options = {}) => {
  const config = {
    method,
    body: JSON.stringify(options.data),
    credentials: 'include',
  };
  if (options.redirect === false) config.redirect = 'manual';

  let urlData = url;
  if (options.query) {
    const query = new URLSearchParams(options.query);
    urlData += urlData.includes('?') ? '&' : '?';
    urlData += query.toString();
  }
  const req = new Request(urlData, config);
  return executeRequest(req, options);
};

const putRequest = async (url, options = {}) => request(url, 'PUT', options);

const postRequest = async (url, options = {}) => request(url, 'POST', options);

const getRequest = async (url, options = {}) => request(url, 'GET', options);

const patchRequest = async (url, options = {}) => request(url, 'PATCH', options);

const deleteRequest = async (url, options = {}) => request(url, 'DELETE', options);

export {
  request, putRequest, postRequest, getRequest, patchRequest, deleteRequest,
};
