import { getField, updateField } from 'vuex-map-fields';
import { getRequest } from 'helper/request';
import { createStore } from 'vuex';

const initialState = (uploadPath, newProjectPath) => ({
  uploadPath,
  newProjectPath,
  inProgress: false,
  failed: false,
  status: null,
  message: '',
  progress: 0,
  processingIntervalId: null,
});

const getters = {
  getField,
};

const mutations = {
  updateField,
  resetProgressDisplay(state) {
    state.inProgress = true;
    state.failed = false;
  },
  setUploadProgress(state, evt) {
    const done = evt.position || evt.loaded;
    const total = evt.totalSize || evt.total;
    state.progress = Math.round((done / total) * 100);
  },
  setUploadProblem(state, message = null) {
    state.message = message;
    state.failed = true;
    state.inProgress = false;
    state.progress = -1;
  },
  setProcessingUpdateInterval(state, intervalId) {
    state.processingIntervalId = intervalId;
  },
  setProcessingStatus(state, { status, message, progress }) {
    state.status = status;
    state.message = message;
    state.progress = parseInt(progress, 10);
  },
  stopProcessingUpdates(state) {
    window.clearInterval(state.processingIntervalId);
    state.processingIntervalId = null;
  },
  setUploadPath(state, uploadPath) {
    state.uploadPath = uploadPath;
  },
};

const actions = {
  updateField,
  async createProject({ commit, state }) {
    const newProject = await getRequest(state.newProjectPath);
    commit('setUploadPath', newProject.upload_url);
  },
  async upload({ commit, dispatch, state }, files) {
    commit('resetProgressDisplay');

    // Creating a new project gives us the path
    if (state.uploadPath === null) {
      try {
        await dispatch('createProject');
      } catch (_) {
        commit('setUploadProblem');
      }
    }

    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = (evt) => {
      dispatch('setUploadStatus', evt);
    };
    (xhr.upload || xhr).onprogress = (evt) => {
      commit('setUploadProgress', evt);
    };
    xhr.onload = function () {
      if (xhr.status === 400) {
        commit('stopProcessingUpdates');
        commit('setUploadProblem', JSON.parse(xhr.responseText).message);
      }
    };

    xhr.open('post', state.uploadPath, true);
    const data = new FormData();
    files.forEach((file) => {
      data.append('upload[file]', file);
    });
    xhr.send(data);
  },
  setUploadStatus({ dispatch, commit }, evt) {
    const xhr = evt.target;
    if (xhr.readyState === 4 && xhr.status === 201) {
      const responseData = JSON.parse(xhr.responseText);
      dispatch('startProcessingUpdates', responseData);
    } else if (xhr.readyState === 4) {
      commit('setUploadProblem');
    }
  },
  startProcessingUpdates({ commit, dispatch }, statusUpdateData) {
    const intervalId = window.setInterval(
      () => {
        dispatch('updateProcessingStatus', statusUpdateData);
      },
      500,
    );
    commit('setProcessingUpdateInterval', intervalId);
  },
  async updateProcessingStatus({ commit, state }, { callback, redirect }) {
    const status = await getRequest(callback);
    commit('setProcessingStatus', status);
    if (state.progress === 100) {
      commit('stopProcessingUpdates');
      window.location.href = redirect;
    }

    if (state.progress === -1 || state.status === 'failed') {
      commit('stopProcessingUpdates');
      commit('setUploadProblem', status.message);
    }
  },
};

const storeFn = (uploadPath, newProjectPath = null) => createStore({
  namespaced: true,
  state: initialState(uploadPath, newProjectPath),
  actions,
  mutations,
  getters,
});

export default storeFn;
